import { MembershipOfferPage } from '@aaa/emember/store-membership-offers';
import { FieldComponent, FieldComponentType } from '../../modules/render';

export const membershipOffers = (page: MembershipOfferPage): FieldComponent[] => {
  const fields: FieldComponent[] = [];

  switch (page) {
    case 'offers':
      {
        fields.push(
          {
            type: FieldComponentType.HEADER2,
            props: { text: 'Join AAA Today.', cls: 'ava-text-xxl ava-text-bolder ava-text-white ava-pb-0 ava-mb-0' },
          },
          {
            type: FieldComponentType.HEADER3,
            props: {
              text: 'Get the best price on our best 1-year plan.',
              cls: 'ava-text-lg ava-pb-4 ava-mb-0 ava-text-white',
            },
          },
          {
            type: FieldComponentType.SECTION,
            props: { cls: 'ava-pb-4' },
            fieldGroup: [
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'Legendary 24/7 Emergency Roadside Assistance ',
                  cls: 'ava-text-md ava-bold-400 ava-text-white',
                },
              },
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'in any car, even when you are a passenger',
                  cls: 'ava-text-md ava-pb-4 ava-bold-200 ava-text-white',
                },
              },
            ],
          },
          {
            type: FieldComponentType.SECTION,
            props: { cls: 'ava-pb-4' },
            fieldGroup: [
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'Travel perks & discounts on hotels and car rentals,',
                  cls: 'ava-text-md ava-bold-400 ava-text-white',
                },
              },
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'including exclusive extras on cruise and tour packages.',
                  cls: 'ava-text-md ava-pb-4 ava-bold-200 ava-text-white',
                },
              },
            ],
          },
          {
            type: FieldComponentType.SECTION,
            props: { cls: 'ava-pb-4' },
            fieldGroup: [
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'Member discounts',
                  cls: 'ava-text-md ava-bold-400 ava-text-white',
                },
              },
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: 'at over 100,000 locations including',
                  cls: 'ava-text-md ava-pb-4 ava-bold-200 ava-text-white',
                },
              },
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: ' restaurants, car repair, theme parks, concerts, movie tickets, ',
                  cls: 'ava-text-md ava-bold-400 ava-text-white',
                },
              },
              {
                type: FieldComponentType.SPAN,
                props: {
                  text: ' and so much more!',
                  cls: 'ava-text-md ava-pb-4 ava-bold-200 ava-text-white',
                },
              },
            ],
          }
        );
      }
      break;
    case 'gift-offers':
      fields.push(
        {
          type: FieldComponentType.PARAGRAPH,
          props: { text: 'AAA Gift', cls: 'ava-text-xxl ava-bold-400 ava-pb-0 ava-mb-0' },
        },
        {
          type: FieldComponentType.PARAGRAPH,
          props: { text: 'Membership', cls: 'ava-text-xxl ava-bold-400 ava-mb-1' },
        },
        {
          type: FieldComponentType.PARAGRAPH,
          props: {
            text: 'Give the Gift of Peace of Mind',
            cls: 'ava-congrats-description ava-text-xl ava-pb-0 ava-mb-0',
          },
        },
        {
          type: FieldComponentType.PARAGRAPH,
          props: {
            text: 'with 24/7 Emergency Roadside Assistance',
            cls: 'ava-congrats-description ava-text-xl ava-pb-0 ava-mb-0',
          },
        },
        {
          type: FieldComponentType.PARAGRAPH,
          props: {
            text: 'Coverage, Provided 365 Days a Year.',
            cls: 'ava-congrats-description ava-text-xl ava-pb-0',
          },
        },
        {
          type: FieldComponentType.PARAGRAPH,
          props: {
            text: 'Holiday Sale Promo: GIFT24',
            cls: 'ava-congrats-description ava-text-lg ava-pb-0 ava-mb-0 ava-bold-400',
          },
        },
        {
          type: FieldComponentType.SPAN,
          props: {
            text: 'Enjoy a ',
            cls: 'ava-congrats-description ava-text-md ava-pb-0 ava-mb-0 ava-bold-200',
          },
        },
        {
          type: FieldComponentType.SPAN,
          props: {
            text: '50% discount ',
            cls: 'ava-congrats-description ava-text-md ava-pb-0 ava-mb-0 ava-bold-400',
          },
        },
        {
          type: FieldComponentType.SPAN,
          props: {
            text: 'at checkout when you purchase a',
            cls: 'ava-congrats-description ava-text-md ava-pb-0 ava-mb-0 ava-bold-200',
          },
        },
        {
          type: FieldComponentType.SPAN,
          props: {
            text: 'AAA Classic Membership. ',
            cls: 'ava-congrats-description ava-text-md ava-pb-0 ava-mb-0 ava-bold-400',
          },
        },
        { type: FieldComponentType.BR },
        {
          type: FieldComponentType.SPAN,
          props: {
            text: 'This special offer also includes a waived enrollment fee. Plus, you can add a second driver for only $25. Hurry, this is a limited-time offer.',
            cls: 'ava-congrats-description ava-text-md ava-pb-3 ava-bold-200',
          },
        }
      );
      break;
  }

  return fields;
};
