import { AppEnv, ClubApp, Environment } from '@aaa/emember/types';

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBbOQIPmreenkMaDDA8yx96_CP6aDpx8GM',
  authDomain: 'aaa-northampton-2.firebaseapp.com',
  databaseURL: 'https://aaa-northampton-2-default-rtdb.firebaseio.com',
  projectId: 'aaa-northampton-2',
  storageBucket: 'aaa-northampton-2.appspot.com',
  messagingSenderId: '289443037050',
  appId: '1:289443037050:web:81ccc6587549be37eca0c3',
  measurementId: 'G-QJ40MGQSBE',
};

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: 'https://test.northampton.millisite.com',
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.Northampton,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: '',
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: 'https://ww2.aaa.com',
  baseHref: 'https://test.northampton.millisite.com/',
  zipcode: '18045',
};
